<template>
  <div>
    <slot></slot>

    <div class="input-group has-validation">
      <textarea
        :id="label"
        :value="modelValue"
        class="form-control w-75"
        :name="label"
        :class="{ 'is-invalid': error }"
        :disabled="disabled"
        :rows="rows"
        @input="emitInput"
      ></textarea>
      <div class="invalid-feedback">
        {{ error?.[0] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormInputWithValidationAndReadOnlyMode',
  components: {},

  props: {
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    error: {
      type: [String, Number, Array, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    label: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {}
  },

  methods: {
    emitInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>

<style lang="scss">
.has-validation {
  > input:disabled,
  select:disabled {
    background-color: white;
    border: 0;
    border-radius: 0;
    border-bottom: 0.5px solid #45b1aa;
    padding-left: 0;
  }
}
</style>
