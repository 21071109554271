<script setup>
import Datepicker from 'vue3-datepicker'

import BaseSelectInput from '@/components/Base/BaseSelectInput.vue'
// import BaseInputDisplay from '@/components/TPD/Base/BaseInputDisplay.vue'

import BaseRequiredLabel from '@/components/Base/BaseRequiredLabel.vue'
import FormInput from '@/components/TPD/Components/FormInput.vue'
import FormTextArea from '@/components/TPD/Components/FormTextArea.vue'

import EstimatedCost from '@/components/ImpairEx/EstimatedCost.vue'

import Select2 from '@/components/Widget/SelectTwo.vue'

import { getLastDayOfYear } from '@/utils/generics.js'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import { ref, computed, onMounted } from 'vue'

// Vuex
const store = useStore()

// VueRouter
const router = useRouter()
const route = useRoute()

// Patient informations

const patientExist = ref(null)
const patientNew = ref({
  country: 'Australia',
})
const patientErrors = ref({})
const patientList = ref([])

// Computed
const displayedPatientData = computed({
  // This is to control display of patient data because
  // select2 plugin doesnt seem to support single clear option, the events arent being emited

  // This extra step is because vue throws an error about
  // not able to read data inside null object when deselecting patient from list

  get() {
    if (patientExist.value) {
      return patientExist.value
    } else {
      return {}
    }
  },

  set(newValue) {
    patientNew.value = true
    return newValue
  },
})
const patientDetailsStateText = computed(() => {
  if (displayedPatientData.value.id) {
    return 'Selected'
  } else return 'New'
})

// Patient related functions

async function getPatientList() {
  let payload = { page: 1 }
  await store.dispatch('getPatientList', payload).then((response) => {
    patientList.value = response.data.results
  })
}

// Form options
const genderOptions = ref([
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
])

// Page state
const pageReady = ref(false)

// Button state
const isSubmitting = ref(false)

// Submit
function submit() {
  isSubmitting.value = true

  return router.push({
    name: 'lawyer-referral-document-uploads',
    params: { id: route.params.id },
  })
}

// Calender props
const currentYear = computed(() => getLastDayOfYear())

// Calander related functions

function convertToDateFormat(date) {
  if (date) {
    const result = new Date(date)

    return result
  } else return null
}

// Lifecycles
onMounted(async () => {
  await getPatientList()

  pageReady.value = true
})
</script>

<template>
  <div v-if="pageReady" class="">
    <h4 class="fs-2 my-5 text-black">ImpairEx Referral</h4>

    <form
      class="container-fluid"
      enctype="multipart/form-data"
      @submit.prevent="submit"
    >
      <div class="row mb-5">
        <div class="col-8 ps-0">
          <div class="patient-details">
            <fieldset>
              <div
                class="d-flex flex-column container align-items-start mb-5 p-0"
              >
                <h4 class="mb-5 fs-3 text-dark">Patient's Details</h4>
                <div class="w-100 d-flex justify-content-between">
                  <Select2
                    v-model="patientExist"
                    class="d-flex flex-grow-1"
                    :label-text="`Please search for existing patient's name:`"
                    :data-array="patientList"
                    :api-call-name="`getTPDPatientList`"
                    :tpd-mode="true"
                    :input-name="`Patient`"
                  >
                    <template #list-footer>
                      <div
                        class="d-flex justify-content-between align-items-center p-2 border-top"
                      >
                        <div class="mt-2">
                          <span class="fw-bold d-block">
                            Can't find a patient?
                          </span>
                          <span class="text-gray-600 fw-light">
                            You can add patient’s details manually to upload a
                            new patient data.
                          </span>
                        </div>
                      </div>
                    </template>
                    <template #option="option">
                      <div
                        v-if="option"
                        class="py-3 px-2 vs-dropdown-listing rounded-3"
                      >
                        <span class="d-block fw-bold text-capitalize">
                          {{ option.first_name }}
                        </span>
                        <em>
                          {{
                            option.gender === 'M'
                              ? 'Male'
                              : option.gender === 'F'
                              ? 'Female'
                              : 'Not Specified'
                          }}
                          , Born in {{ option.date_of_birth }}
                        </em>
                      </div>
                    </template>
                  </Select2>
                </div>
              </div>

              <!-- patient details section -->
              <form id="patient_info_section" ref="patientSection" class="my-5">
                <span class="text-primary">
                  <strong>
                    {{ displayedPatientData.id ? 'Selected' : 'New' }} Patient
                  </strong>
                </span>
                <div class="my-4 row">
                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.first_name"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.first_name"
                      :label="`patient_first_name`"
                    >
                      <label class="form-check-label" for="patient_first_name">
                        First Name
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>

                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.middle_name"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.middle_name"
                      :label="`patient_middle_name`"
                    >
                      <label class="form-check-label" for="patient_middle_name">
                        Middle Name
                      </label>
                    </FormInput>
                  </div>

                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.last_name"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.last_name"
                      :label="`patient_last_name`"
                    >
                      <label class="form-check-label" for="patient_last_name">
                        Last Name
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>
                </div>

                <div class="my-4 row">
                  <div class="col">
                    <BaseSelectInput
                      v-model="displayedPatientData.gender"
                      :label="`Gender`"
                      :options="genderOptions"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :required="true"
                    />
                  </div>
                  <div class="col">
                    <label class="form-check-label" for="patient_date_of_birth">
                      Date of Birth
                      <BaseRequiredLabel />
                    </label>

                    <datepicker
                      id="patient_date_of_birth"
                      v-model="displayedPatientData.date_of_birth"
                      :model-value="
                        convertToDateFormat(displayedPatientData.date_of_birth)
                      "
                      autocomplete="off"
                      input-format="dd MMMM yyyy"
                      :upper-limit="currentYear"
                      class="datepicker-input-box has-validation"
                      :class="
                        patientErrors.date_of_birth ? 'border-danger' : ''
                      "
                      style="
                        --vdp-selected-bg-color: #32c3d6;
                        --vdp-hover-bg-color: #32c3d6;
                      "
                      starting-view="year"
                      :disabled="patientDetailsStateText === 'Selected'"
                    />
                    <div
                      v-if="patientErrors.date_of_birth"
                      class="error-warning"
                    >
                      {{ patientErrors.date_of_birth?.[0] }}
                    </div>
                  </div>

                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.contact_number"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.contact_number"
                      :label="`contact_number`"
                      :type="`text`"
                    >
                      <label class="form-check-label" for="patient_contact_num">
                        Contact Number
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>
                </div>

                <div class="my-4 row">
                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.emr_number"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.emr_number"
                      :label="`emr_number`"
                      :type="`number`"
                    >
                      <label class="form-check-label" for="patient_emr_number">
                        EMR Number
                      </label>
                    </FormInput>
                  </div>
                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.claim_number"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.claim_number"
                      :label="`Suburb`"
                      :type="`number`"
                    >
                      <label
                        class="form-check-label"
                        for="patient_claim_number"
                      >
                        Membership/Claim Number
                      </label>
                    </FormInput>
                  </div>

                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.email"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.email"
                      :label="`Email`"
                      :type="`email`"
                    >
                      <label class="form-check-label" for="patient_email">
                        Email
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>
                </div>

                <div class="my-4 row">
                  <div class="col">
                    <FormTextArea
                      id="patient_address"
                      v-model="displayedPatientData.street_address"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.street_address"
                    >
                      <label class="form-check-label" for="patient_address">
                        Address
                        <BaseRequiredLabel />
                      </label>
                    </FormTextArea>
                  </div>
                </div>

                <div class="my-4 row">
                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.suburb"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.suburb"
                      :label="`Suburb`"
                    >
                      <label class="form-check-label" for="patient_suburb">
                        Suburb
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>
                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.state"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.state"
                      :label="`State`"
                    >
                      <label class="form-check-label" for="patient_state">
                        State
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>
                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.country"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.country"
                      :label="`Country`"
                    >
                      <label class="form-check-label" for="patient_country">
                        Country
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>
                  <div class="col">
                    <FormInput
                      v-model:modelValue="displayedPatientData.postcode"
                      :disabled="patientDetailsStateText === 'Selected'"
                      :error="patientErrors.postcode"
                      :label="`ZIP/Postal Code`"
                    >
                      <label class="form-check-label" for="patient_postal_code">
                        ZIP/Postal Code
                        <BaseRequiredLabel />
                      </label>
                    </FormInput>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>

          <!-- <div>
            <div class="referrer-details my-5">
              <div class="container p-0">
                <h4 class="mb-5 fs-3 text-dark">Referrer’s Details</h4>
                <div class="my-4 row">
                  <div class="col">
                    <BaseInputDisplay
                      :label="'First Name'"
                      :value="lawyer.first_name"
                    />
                  </div>

                  <div v-if="lawyer.middle_name" class="col">
                    <BaseInputDisplay
                      :label="'Middle Name'"
                      :value="lawyer.middle_name"
                    />
                  </div>

                  <div class="col">
                    <BaseInputDisplay
                      :label="'Last Name'"
                      :value="lawyer.last_name"
                    />
                  </div>
                </div>

                <div class="my-4 row">
                  <div class="col">
                    <BaseInputDisplay
                      :label="'Company'"
                      :value="lawyer.company_name"
                    />
                  </div>

                  <div class="col">
                    <BaseInputDisplay
                      :label="'Contact Number'"
                      :value="lawyer.contact_number"
                    />
                  </div>

                  <div class="col">
                    <BaseInputDisplay :label="'Email'" :value="lawyer.email" />
                  </div>
                </div>
                <div class="my-4 row">
                  <div class="col-12">
                    <BaseInputDisplay
                      :label="'Address'"
                      :value="lawyer.address"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="col estimated-cost d-flex flex-column">
          <EstimatedCost cost="X.00" />

          <div class="">
            <button
              type="submit"
              class="btn btn-success rounded-3 text-white my-3 w-100"
              :disabled="isSubmitting"
            >
              <div v-if="isSubmitting" class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
              <span v-else>SAVE REFERRAL</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div
    v-else
    class="min-vh-100 d-flex justify-content-center align-items-center"
  >
    <div>
      <div
        class="spinner-border text-primary"
        style="width: 12rem; height: 12rem"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.patient-details,
.estimated-cost,
.referrer-details {
  display: flex;
  align-items: top;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.estimated-cost {
  max-height: 500px;
  max-width: 23%;
  position: fixed;
  top: 0;
  right: 5%;
  transform: translateY(76%);
}

#createReferralBtn {
  max-width: 250px;
}

.disabled {
  &-textarea {
    &:disabled {
      margin-top: 10px;
      background-color: white !important;

      border: 0.5px solid #45b1aa;
    }
  }
}

.enable-pointer {
  cursor: pointer;
}

.icon-white {
  filter: invert(100%) sepia(100%) saturate(24%) hue-rotate(3deg)
    brightness(107%) contrast(106%);
}
</style>
